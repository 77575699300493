<template>
  <div class="main">
    <div class="content">
      <div class="title">
        <p>资讯中心</p>
        <span @click="$router.push('/countyCityZone/newsPage')">查看更多 <i class="el-icon-arrow-right"></i></span>
      </div>
      <div class="tabs">
        <p class="tab" :class="{'active':  activeIndex === '1'}" @click="getNewsList('1')">新闻资讯</p>
        <p class="tab" :class="{'active':  activeIndex === '2'}" @click="getNewsList('2')">通知公告</p>
      </div>
      <div class="news">
        <div class="news-list">
          <ul class="news-list-ul">
            <li class="news-list-li" v-for="item in newsList" :key="item">
              <div class="news-img">
                <img :src="item.image" alt="">
              </div>
              <div class="news-info">
                <p class="news-title">{{ item.name }}</p>
              <p class="news-time">{{ item.publish }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="title">
        <p>课程专区</p>
        <span @click="$router.push('/countyCityZone/coursePage?ID=' + $route.query.ID)">查看更多 <i class="el-icon-arrow-right"></i></span>
      </div>
      <div class="course">
        <div class="course-tabs">
          <div class="tab-list">
            <p v-for="(item, index) in courseModuleList" :key="index" class="tab" :class="{'active':  item.id === '1'}" @click="getCourseList(item.id)">{{ item.name }}</p>
          </div>
        </div>
        <div class="course-list">
          <ul>
            <li v-for="item in courseList" :key="item">
              <div class="course-img">
                <img src="@/assets/index/banner.png" alt="">
              </div>
              <div class="course-info">
                <p class="course-title">{{ item.name }}</p>
                <!-- <p class="course-teacher"> 
                  <span>主讲老师：</span>
                  <span class="teacher-name">朱先波</span>
                </p> -->
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'advicePage',
  data () {
    return {
      ID: '',
      activeIndex: '1',
      tabIndex: '1',
      newsList: [],
      newsHover: {},
      courseList: [],
      courseModuleList: []
    }
  },
  created () {
    this.ID = this.$route.query.ID
    this.getNewsList('1')
    this.getCourseModuleList()
  },
  methods: {
    getNewsList (index) {
      this.activeIndex = index;
      this.newsList = [];
      this.newsHover = {};
      this.$axios.post(`/v1/qn/newsList`, { area_id: this.ID, type: this.activeIndex }).then((res) => {
          if (res.code == 0) {
            this.newsList = res.data.data;
            this.newsHover = res.data.data[0];
          }
        });
    },
    getCourseModuleList() {
      this.$axios.post(`/v1/qn/courseModuleList`, { area_id: this.ID }).then((res) => {
        if (res.code == 0) {
          this.courseModuleList = res.data.data;
          if (res.data.data.length > 0) {
            this.getCourseList(res.data.data[0].id)
          }
        }
      });
    },
    getCourseList (id) {
      this.tabIndex = id;
      this.courseList = [];
      this.$axios.post(`/v1/qn/courseList`, { area_id: this.ID, module_id: id }).then((res) => {
        if (res.code == 0) {
          this.courseList = res.data.data;
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.main {
  background-color: #F7FAFF;
  .content {
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 120px;
    background-color: #FFFFFF;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      p {
        font-family: AlimamaShuHeiTi;
        font-weight: bold;
        font-size: 32px;
        color: #366CEC;
        line-height: 38px;
      }
      span {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        font-weight: normal;
        font-size: 22px;
        color: #666666;
        line-height: 32px;
        text-align: left;
        cursor: pointer;
      }
    }
    .tabs {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      .tab {
        height: 58px;
        padding: 0px 24px;
        background: #F0F6FF;
        border-radius: 5px 5px 5px 5px;
        font-weight: 500;
        font-size: 24px;
        color: #7EA0F0;
        line-height: 58px;
        text-align: center;
        margin: 0 25px;
      }
      .active {
        cursor: pointer;
        background: #366CEC;
        color: #FFFFFF;
      }
    }
    .news {
      padding: 28px;
      box-sizing: border-box;
      margin-top: 10px;
      border-bottom: 10px solid #F5F6F7;
      margin-bottom: 40px;
      .news-list {
        margin-left: 15px;
        .news-list-ul {
          .news-list-li {
            border-bottom: 1px solid #70707019;
            display: flex;
            padding: 32px 0;
            .news-img {
              width: 275px;
              height: 168px;
              border-radius: 6px;
              img {
                width: 100%;
                height: 100%;
                border-radius: 6px;
              }
            }
            .news-info {
              width: 392px;
              margin-left: 26px;
            }
            .news-title {
              font-weight: 500;
              font-size: 28px;
              color: #333333;
              line-height: 40px;
              text-align: left;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
            .news-time {
              font-weight: 500;
              font-size: 24px;
              color: #666666;
              line-height: 34px;
              text-align: left;
              margin-top: 34px;
            }
            &:last-child {
              border-bottom: 1px solid #FFFFFF;
            }
          }
        }
      }
    }
    .course {
      padding: 28px;
      .course-tabs {
        margin-top: 40px;
        overflow: auto;
        .tab-list {
          display: flex;
          width: 1500px;
        }
        .tab {
          height: 58px;
          padding: 0px 24px;
          background: #F0F6FF;
          border-radius: 5px 5px 5px 5px;
          font-weight: 500;
          font-size: 24px;
          color: #7EA0F0;
          line-height: 58px;
          text-align: center;
          margin: 0 12px;
        }
        .active {
          cursor: pointer;
          background: #366CEC;
          color: #FFFFFF;
        }
      }
      .course-list {
        margin-top: 20px;
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            margin: 12px 6px;
            .course-img {
              width: 334px;
              height: 204px;
              border-radius: 10px 10px 0px 0px;
              img {
                width: 100%;
                height: 100%;
                border-radius: 10px 10px 0px 0px;
              }
            }
            .course-info {
              width: 334px;
              // height: 134px;
              padding: 24px 20px;
              box-sizing: border-box;
              background: #FFFFFF;
              box-shadow: 0px 10px 18px 1px rgba(180,180,180,0.16);
              border-radius: 0px 0px 10px 10px;
              .course-title {
                font-weight: bold;
                font-size: 24px;
                color: #333333;
                line-height: 25px;
                text-align: left;
                margin-top: 12px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }
              .course-teacher {
                display: flex;
                align-items: center;
                margin-top: 20px;
                span {
                  font-weight: bold;
                  font-size: 22px;
                  line-height: 23px;
                  color: #666666;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>